import React, { useEffect } from "react"
import LoginLayout from 'layouts/Layout'
import { LoginComponent } from "@/components/pages"

const Login = (props) => {

	useEffect(() => {
		sessionStorage.clear();
	});

	return <LoginComponent />
}
Login.Layout = LoginLayout
export default Login
